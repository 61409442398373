<template>
  <div class="action-post-mortem">
    <h5>{{ actionLibelle }}</h5>
    <div class="description">
      {{ descriptionAction }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        actionDetail:  {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            actions: 'identification/actionsPostMortem'
        }),
        action(){ return this.actions.find(item => item.uid === this.actionDetail.action) },
        actionLibelle(){return this.hasAction? this.action.libelle : '-'},
        hasAction(){ return this.action !== null && this.action !== undefined},
        descriptionAction(){
            return this.actionDetail.description
        }
    }
}
</script>

<style lang="scss" scoped>
.action-post-mortem{
    margin: 10px 10px 10px 20px;
    padding-left: 20px;
    border-left: 1px solid rgba($color: #000000, $alpha: 0.12);
    .description{
        
    }
    h5{
        font-size: 14px;
        color: #1C6100;
    }
}
</style>