<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <detail-sanitaire
      :animal="animal"
      :create-by="createBy"
      :create-at="createAt"
    >
      <template slot="header"> 
        <h4>{{ motif }}</h4>
      </template>
      <template slot="content">
        <div class="row info">
          <div class="col-12">
            <h6>Motif</h6>
            {{ motifDeces }}
          </div>
        </div>
        <div class="row info">
          <div class="col-12">
            <h6>Date Décès</h6>
            {{ dateDeces|systemDateTime }}
          </div>
        </div>
        <div class="row info">
          <div class="col-12">
            <h6>Actions post Mortem</h6>
            <detail-action-post-mortem
              v-for="(a, i) in postMortemActions"
              :key="i"
              :action-detail="a"
            />
          </div>
        </div>
             
             
        <div class="row info">
          <div class="col-12">
            <h6>Information Supplémentaire</h6>
            {{ info }}
          </div>
        </div>
      </template>
    </detail-sanitaire>
  </div>
</template>
  
  <script>
  import navbar from '../../../components/navbar.vue';
  import DetailSanitaire from '../../../components/sanitaire/detailSanitaire.vue'
  import DetailActionPostMortem from '../../../components/identification/declarationDeces/detailActionPostMortem.vue'
  import { mapGetters } from 'vuex';
  export default {
      components: {navbar, DetailSanitaire, DetailActionPostMortem},
      data(){
          return {
              navbarItems: [
                  { libelle: 'Identification' },
                  { libelle: 'Déclaration de décès', route: 'declaration-deces' },
                  { libelle: 'Détail' }
              ],
              pageIcon: 'la-skull-crossbones',
              pageTitle: 'Détail',
              pageDescription: 'Déclaration décès détail',
              declaration: null
          }
      },
      watch: {
        declarations: {
              handler(){
                  this.load()
              },
              deep: true
          }
      },
      mounted(){
          this.load()
      },  
      methods: {
          load(){
              if(this.$route.params.uid !== null){
                  this.declaration =  this.declarations.find(item => item.uid === this.$route.params.uid)
              }else{
                  this.declaration =  null
              }
              
          }
      },
      computed: {
          ...mapGetters({
              declarations: 'identification/declarationDeces',
              motifs: 'identification/motifDeces',
          }),
          hasDeclaration() { return this.declaration !== null && this.declaration !== undefined},
          animal(){
              if(this.hasDeclaration) return this.declaration.animal
              return null
          },
          createBy(){
              return (this.hasDeclaration)? this.declaration.createBy:null
          },
          createAt(){
              return (this.hasDeclaration)? this.declaration.createdAt:null
          },
          info(){ return this.hasDeclaration? this.declaration.info: null},
          motifDeces(){
            if(this.hasDeclaration){
                let m = this.motifs.find(item => item.uid === this.declaration.motif)
                if(m!== undefined && m!== null) return m.libelle
            }
            return '-'
          },
          dateDeces(){
            return this.hasDeclaration? this.declaration.dateDeces : null
          },
          postMortemActions(){
            return this.hasDeclaration? JSON.parse(this.declaration.actionsPostMortem): []
          }
  
      }
  
  }
  </script>
  
  <style>
  
  </style>